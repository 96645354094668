import React from "react";
// import { graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import Search from "./Search";

// export const query = graphql`
//   query {
//     allAllPoolsJson {
//       nodes {
//         name
//         slug
//       }
//     }
//     allAllCoinsJson {
//       nodes {
//         name
//         abbreviation
//         slug
//       }
//     }
//     allAllAlgorithmsJson {
//       nodes {
//         name
//         slug
//       }
//     }
//   }
// `;

const SearchPage = ({ pageContext }) => {
  // const pools = data.allAllPoolsJson.nodes;
  // const coins = data.allAllCoinsJson.nodes;
  // const algorithms = data.allAllAlgorithmsJson.nodes;

  const { coins, pools, algorithms } = pageContext;

  return (
    <Root>
      <SEO title="Search algorithms, coins and mining pools" description="Search algorithms, coins and mining pools on WhereToMine.io" />
      <Search coins={coins} algorithms={algorithms} pools={pools} />
    </Root>
  );
};

export default SearchPage;
