import React, { useState } from "react";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Profile from "../Constant/Profile";
import BreadcrumsControls from "../Constant/BreadcrumsControls";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    minHeight: "1080px",
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    fontWeight: "600",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkSubtitle: {
    fontWeight: "500",
    fontSize: "0.75rem",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

const Search = props => {
  const [filteredCoins, setFilteredCoins] = useState([]);
  const [filteredAlgorithms, setFilteredAlgorithms] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { pools, coins, algorithms } = props;
  const classes = useStyles(props);

  const breadcrums = [{ to: "/", page: "Home" }];

  const handleSearchTerm = (e, value) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    handleFilter(searchTerm);
  };

  const handleFilter = searchTerm => {
    const a = algorithms.filter(algorithm => {
      return algorithm.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredAlgorithms(a);

    const c = coins.filter(coin => {
      const coinName = `${coin.name.toLowerCase()} (${coin.abbreviation.toLowerCase()})`;
      return coinName.includes(searchTerm.toLowerCase());
    });

    setFilteredCoins(c);

    const p = pools.filter(pool => {
      return pool.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredPools(p);
  };

  return (
    <div className={classes.root}>
      <BreadcrumsControls breadcrums={breadcrums} currentPage={`Search`} />

      <section style={{ marginBottom: 40 }}>
        <Profile title={"Search"} additionalTitle={""} />
      </section>

      <section style={{ marginBottom: 40 }}>
        <Container maxWidth="lg">
          <TextField
            style={{ width: "100%" }}
            label="Search algorithms, coins, pools..."
            variant="outlined"
            onChange={e => handleSearchTerm(e)}
          />
        </Container>
      </section>
      <section>
        <Container maxWidth="lg">
          <div>
            <div style={{ marginBottom: 40 }}>
              {filteredAlgorithms.length > 0 ? (
                <Typography className={classes.headerTitle} variant="h4">
                  Algorithms
                </Typography>
              ) : null}
              {searchTerm.length > 1
                ? filteredAlgorithms.map((algorithm, index) => {
                    return (
                      <div key={index} style={{ marginBottom: 10 }}>
                        <Link className={classes.link} to={`/algorithms/${algorithm.slug}`}>
                          <Typography className={classes.linkTitle} variant="subtitle1">
                            {algorithm.name}
                          </Typography>
                        </Link>
                      </div>
                    );
                  })
                : null}
            </div>

            <div style={{ marginBottom: 40 }}>
              {filteredCoins.length > 0 ? (
                <Typography className={classes.headerTitle} variant="h4">
                  Coins
                </Typography>
              ) : null}
              {searchTerm.length > 1
                ? filteredCoins.map((coin, index) => {
                    return (
                      <div key={index} style={{ marginBottom: 10 }}>
                        <Link className={classes.link} to={`/coins/${coin.slug}`}>
                          <Typography className={classes.linkTitle} variant="subtitle1">
                            {coin.name} ({coin.abbreviation})
                          </Typography>
                        </Link>
                      </div>
                    );
                  })
                : null}
            </div>

            <div style={{ marginBottom: 40 }}>
              {filteredPools.length > 0 ? (
                <Typography className={classes.headerTitle} variant="h4">
                  Pools
                </Typography>
              ) : null}
              {searchTerm.length > 1
                ? filteredPools.map((pool, index) => {
                    return (
                      <div key={index} style={{ marginBottom: 10 }}>
                        <Link className={classes.link} to={`/pools/${pool.slug}`}>
                          <Typography className={classes.linkTitle} variant="subtitle1">
                            {pool.name}
                          </Typography>
                        </Link>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Search;
